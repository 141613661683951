import IMask from "imask";
import type { FC } from "react";

import React from "react";
import AppDialogTarget from "src/ui/components/AppDialog/AppDialogTarget";
import CustomSuspense from "src/ui/components/CustomSuspense/CustomSuspense";
import AppDomainRedirect from "../AppDomainRedirect/AppDomainRedirect";
import VideoPopup from "../FullVideoPlayer/VideoPopup";
import ViewportManager from "../ViewportManager/ViewportManager";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
const w = window as any;
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
w.IMask = IMask;

const AppAsyncExtras: FC = () => {
  return (
    <>
      <AppDomainRedirect />
      <CustomSuspense>
        <AppDialogTarget />
      </CustomSuspense>
      <VideoPopup />
      <ViewportManager />
    </>
  );
};

export default AppAsyncExtras;
