import { Cubit } from "blac";
import { useEffect } from "react";
import { isHybridApp } from "src/lib/platform";

export class ViewportManagerBloc extends Cubit<null> {
  undoKeyboardActivatedScrolling = (scrollingEvent?: Event) => {
    scrollingEvent?.preventDefault();
    if (isHybridApp()) {
      return;
    }

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  };
}

export const ViewportManagerController = new ViewportManagerBloc(null);

const ViewportManager = () => {
  useEffect(() => {
    let interval: number | null = null;
    window.addEventListener(
      "scroll",
      ViewportManagerController.undoKeyboardActivatedScrolling
    );

    interval = window.setInterval(() => {
      ViewportManagerController.undoKeyboardActivatedScrolling();
    }, 100);

    return () => {
      window.removeEventListener(
        "scroll",
        ViewportManagerController.undoKeyboardActivatedScrolling
      );

      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, []);

  return null;
};

export default ViewportManager;
