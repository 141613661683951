import { useEffect } from "react";
import UserCubit from "src/state/UserCubit/UserCubit";
import { useBloc } from "src/state/state";

const DOMAIN_MAP: Record<string, string | undefined> = {
  "app.9am.health": "app.join9am.com",
  "app.dev.9am.health": "app.dev.join9am.com",
  "app.qa.9am.health": "app.qa.join9am.com"
  // "localhost:3000": "192.168.1.170:3000"
  // '192.168.1.170:3000': 'localhost:3000'
};

const AppDomainRedirect = () => {
  const [{ userData }] = useBloc(UserCubit);
  useEffect(() => {
    const url = new URL(window.location.href);
    const { host } = url;
    const NEW_DOMAIN = DOMAIN_MAP[host];

    // do nothing if we are not on the new domain
    if (!NEW_DOMAIN) {
      return;
    }

    const userLoaded = Boolean(userData?.analyticsToken);

    // do nothing if we don't have user data yet
    if (!userLoaded) {
      return;
    }

    const userLoggedIn = Boolean(userData?.email && userData.passwordSet);

    // do nothing if the user is logged in
    if (userLoggedIn) {
      return;
    }

    url.host = NEW_DOMAIN;

    // redirect to the new domain
    window.location.replace(url.toString());
  }, [userData]);

  return null;
};

export default AppDomainRedirect;
